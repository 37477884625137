import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Radio } from 'antd';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';

import { DurationType } from '../../../1_shared/config/interfaces/DurationType';
import { days } from '../../../1_shared/constants/days';
import {
  Button,
  RadioButton,
  TimePicker,
  Typography,
} from '../../../1_shared/ui';
import { schema } from '../config/schema';
import useAddScheduleOnWeek from '../module/useAddScheduleOnWeek';
import { capitalizeFirstLetter } from '../utlis/capitalizeFirstLetter';
import { getDates } from '../utlis/getDates';

import { IModalScheduleWeek } from './interfaces/IModalScheduleWeek';
import { IWeekScheduleForm } from './interfaces/IWeekScheduleForm';

import styles from './ModalScheduleWeek.module.scss';

const ModalScheduleWeek = ({
  open,
  onClose,
  activeWeek,
  addEvents,
}: IModalScheduleWeek) => {
  const { control, handleSubmit, formState, reset } =
    useForm<IWeekScheduleForm>({
      resolver: yupResolver(schema as any),
      mode: 'onTouched',
    });
  const { onAdd } = useAddScheduleOnWeek();

  const onSubmit = async (data: IWeekScheduleForm) => {
    try {
      await onAdd(data);
      addEvents();
      reset();
      onClose();
    } catch {
      reset();
    }
  };

  const disabledTimes = (): any => ({
    disabledHours: () => [23, 0, 1, 2, 3, 4, 5],
  });

  const handleChangeDates = (
    value: string[],
    val: Dayjs,
    onChange: (value: string[]) => void,
  ) => {
    if (value && value?.includes(dayjs(val).format('YYYY-MM-DD'))) {
      onChange(value.filter(temp => temp !== dayjs(val).format('YYYY-MM-DD')));
    } else {
      onChange(
        value
          ? [...value, val.format('YYYY-MM-DD')]
          : [val.format('YYYY-MM-DD')],
      );
    }
  };

  const dates = getDates(activeWeek);

  return (
    <Modal
      centered
      open={open}
      onCancel={onClose}
      footer={null}
      className={styles.modal}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
        <div className={styles.header}>
          <Typography className={styles.headerTitle}>
            {`Рабочее время в период\nс ${dayjs(activeWeek).format('D')} по  ${dayjs(activeWeek).add(6, 'days').format('D MMMM')}`}
          </Typography>
          <Typography>Тип сессий — личные консультации</Typography>
        </div>
        <div className={styles.timeWrapper}>
          <div className={styles.itemWraper}>
            <Typography type="subtitle" className={styles.itemTitle}>
              ВРЕМЯ РАБОТЫ
            </Typography>
            <div className={styles.time}>
              <Controller
                control={control}
                name="dayStartAt"
                render={({ field: { value, onChange } }) => (
                  <TimePicker
                    showNow={false}
                    needConfirm={false}
                    value={value ? dayjs(value, 'HH:mm') : null}
                    onChange={onChange}
                    label="C"
                    disabledTime={disabledTimes}
                  />
                )}
              />
              <Typography className={styles.timeSlash}>—</Typography>
              <Controller
                control={control}
                name="dayEndAt"
                render={({ field: { value, onChange } }) => (
                  <TimePicker
                    showNow={false}
                    needConfirm={false}
                    value={value ? dayjs(value, 'HH:mm') : null}
                    onChange={onChange}
                    label="ДО"
                    disabledTime={disabledTimes}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.itemWraper}>
            <Typography type="subtitle" className={styles.itemTitle}>
              ПЕРЕРЫВ
            </Typography>
            <div className={styles.time}>
              <Controller
                control={control}
                name="breakStartAt"
                render={({ field: { value, onChange } }) => (
                  <TimePicker
                    value={value ? dayjs(value, 'HH:mm') : null}
                    onChange={onChange}
                    label="С"
                    showNow={false}
                    needConfirm={false}
                    disabledTime={disabledTimes}
                  />
                )}
              />
              <Typography className={styles.timeSlash}>—</Typography>
              <Controller
                control={control}
                name="breakEndAt"
                render={({ field: { value, onChange } }) => (
                  <TimePicker
                    value={value ? dayjs(value, 'HH:mm') : null}
                    onChange={onChange}
                    showNow={false}
                    needConfirm={false}
                    label="ДО"
                    disabledTime={disabledTimes}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles.timeWrapper}>
          <Typography type="subtitle" className={styles.itemTitle}>
            ДЛИТЕЛЬНОСТЬ СЕАНСА
          </Typography>
          <div className={styles.itemWraper}>
            <Controller
              control={control}
              name="duration"
              render={({ field: { value, onChange } }) => (
                <Radio.Group
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  className={styles.radioGroup}
                >
                  <RadioButton
                    value={DurationType.Hour}
                    className={cn(styles.radio, {
                      [styles.radioChecked]: value === DurationType.Hour,
                    })}
                  >
                    1 час
                  </RadioButton>
                  <RadioButton
                    value={DurationType.HourAndHalf}
                    className={cn(styles.radio, {
                      [styles.radioChecked]: value === DurationType.HourAndHalf,
                    })}
                  >
                    1,5 часа
                  </RadioButton>
                </Radio.Group>
              )}
            />
          </div>
        </div>
        <div className={styles.itemWraper}>
          <Typography type="subtitle" className={styles.itemTitle}>
            ВЫБРАТЬ РАБОЧИЕ ДНИ НА НЕДЕЛЕ
          </Typography>
          <Controller
            name="dates"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className={styles.btnsWrapp}>
                {dates.map(val => (
                  <Button
                    disabled={
                      dayjs(val).toDate().getTime() < dayjs().toDate().getTime()
                    }
                    onClick={() => {
                      handleChangeDates(value, val, onChange);
                    }}
                    className={cn(styles.radio, {
                      [styles.radioChecked]:
                        value &&
                        value.includes(dayjs(val).format('YYYY-MM-DD')),
                    })}
                  >
                    <Typography>
                      {capitalizeFirstLetter(days[dayjs(val).day()])}
                    </Typography>
                  </Button>
                ))}
              </div>
            )}
          />
        </div>
        <div className={styles.footer}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            ПРИМЕНИТЬ
          </Button>
          <Button type="secondary" onClick={onClose}>
            ОТМЕНИТЬ
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalScheduleWeek;
