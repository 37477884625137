import { Controller, useFormContext } from 'react-hook-form';
import { CloseCircleFilled } from '@ant-design/icons';
import { Flex } from 'antd';
import dayjs from 'dayjs';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { IOption } from '1_shared/config/interfaces/IOption';
import {
  dayTypeFilterOptions,
  experienceFilterOptions,
  sexFilterOptions,
} from '1_shared/constants/filterEnums';
import { DatePicker, Input, Select, TimePicker, Typography } from '1_shared/ui';

import { IFilterFormProps } from './interfaces/IFilterFormProps';

import styles from './FilterForm.module.scss';

const FiltersForm = ({ handleFieldChange }: IFilterFormProps) => {
  const { control, watch, getValues } =
    useFormContext<ISpecialistListFilters>();

  watch('moneyFilter.minMoneyRate');

  const { data: workWithOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/work-withs',
    getDictionaries,
  );

  const { data: keyThemesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/key-themes',
    getDictionaries,
  );

  const { data: specialitiesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/specialities',
    getDictionaries,
  );

  return (
    <div className={styles.root}>
      <div className={styles.workWithContainer}>
        <Controller
          name="workWith"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Все темы"
              name="workWith"
              label="с кем работают"
              className={styles.workWith}
              value={
                value &&
                workWithOptions.find((option: any) => option.value === value)
              }
              options={workWithOptions}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />

        <Controller
          name="keyThemes"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="Ключевые темы"
              name="keyThemes"
              label="ключевые темы"
              optionFilterProp="label"
              className={styles.workWith}
              options={keyThemesOptions}
              suffixIcon={
                value && value.length > 0 &&(
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              value={value}
              mode="multiple"
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />

        <Controller
          name="specialties"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Любые"
              name="specialties"
              label="специальность"
              value={value}
              className={styles.workWith}
              options={specialitiesOptions}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />
        <Flex gap={5}>
          <Controller
            name="moneyFilter.minMoneyRate"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="От"
                type="number"
                name="minMoneyRate"
                value={value}
                min='0'
                label="диапазон цен (от)"
                onChange={e => {
                  onChange(e.target.value || null);
                  handleFieldChange();
                }}
                onKeyDown={e => {
                  if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                    e.preventDefault();
                  }
                }}
              />
            )}
          />
          <Controller
            name="moneyFilter.maxMoneyRate"
            control={control}
            rules={
              getValues('moneyFilter.minMoneyRate')
                ? {
                    min: getValues('moneyFilter.minMoneyRate'),
                  }
                : undefined
            }
            render={({ field, fieldState: { error } }) => (
              <Flex gap={5} vertical>
                <Input
                  placeholder="До"
                  name="maxMoneyRate"
                  type="number"
                  min='0'
                  label="до"
                  value={field.value}
                  onChange={e => {
                    field.onChange(e.target.value || null);
                    handleFieldChange();
                  }}
                  onKeyDown={e => {
                    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                      e.preventDefault();
                    }
                  }}
                />
                {error && (
                  <Typography type="description" className={styles.error}>
                    Сумма До должна быть больше От
                  </Typography>
                )}
              </Flex>
            )}
          />
        </Flex>

        <Controller
          name="sex"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="Не важен"
              name="sex"
              label="пол"
              value={value}
              options={sexFilterOptions}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />
      </div>
      <div className={styles.dateContainer}>
        <Controller
          name="slotsFilter.date"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              name="date"
              showNow={false}
              minDate={dayjs().add(1, 'day')}
              value={value ? dayjs(value) : null}
              label="дата"
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
              onKeyDown={e => {
                if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                  e.preventDefault();
                }
              }}
            />
          )}
        />

        <Controller
          name="slotsFilter.localTimeFrom"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TimePicker
              onChange={tempValue => {
                onChange(tempValue ? dayjs(tempValue).format('HH:mm') : null);
                handleFieldChange();
              }}
              onKeyDown={e => {
                if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                  e.preventDefault();
                }
              }}
              needConfirm={false}
              value={value ? dayjs(value, 'HH:mm') : null}
              showNow={false}
              name="time"
              label="время от"
            />
          )}
        />

        <Controller
          name="slotsFilter.localTimeTo"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TimePicker
              onChange={tempValue => {
                onChange(tempValue ? dayjs(tempValue).format('HH:mm') : null);
                handleFieldChange();
              }}
              minDate={
                getValues('slotsFilter.localTimeFrom')
                  ? dayjs(getValues('slotsFilter.localTimeFrom'), 'HH:mm').add(
                      1,
                      'hour',
                    )
                  : undefined
              }
              onKeyDown={e => {
                if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                  e.preventDefault();
                }
              }}
              value={value ? dayjs(value, 'HH:mm') : null}
              needConfirm={false}
              showNow={false}
              name="time"
              label="время до"
            />
          )}
        />

        <Controller
          name="slotsFilter.dayType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Не важен"
              name="dayType"
              label="тип дня"
              value={value}
              options={dayTypeFilterOptions}
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
            />
          )}
        />

        <Controller
          name="experience"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Не важен"
              name="experience"
              value={value}
              label="опыт"
              options={experienceFilterOptions}
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
            />
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              placeholder="Иванов"
              type="string"
              name="surname"
              value={value}
              label="Фамилия специалиста"
              onInput={e => {
                onChange((e.target as HTMLInputElement)?.value || null);
                handleFieldChange();
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default FiltersForm;
