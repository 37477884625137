import { Dispatch, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CallEvents, EndpointEvents } from 'voximplant-websdk';
import { Call } from 'voximplant-websdk/Call/Call';
import { Endpoint } from 'voximplant-websdk/Call/EndPoints/Endpoint';
import { Client } from 'voximplant-websdk/Client';

import {
  ConferenceEvents,
  ConferenceStatus,
  DevicesStates,
} from '1_shared/constants/conference';

import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { env } from '../../../env';

const useConferenceSubscriptions = (
  voxImplant: Client,
  currentCall: Call | null,
  setCurrentCall: Dispatch<Call | null>,
  remoteMicro: DevicesStates,
  remoteVideo: DevicesStates,
  camera: boolean,
  setParticipantVideo: any,
  setParticipantVoice: any,
  endpoints: Endpoint[],
  setEndpoints: Dispatch<Endpoint[]>,
  setStatus: Dispatch<ConferenceStatus>,
  sendMessage: any,
) => {
  const { user } = useAuthContext();
  const params = useParams();

  const filterSelfEndpoint = (e: any) => e.userName !== user?.id;

  const createCall = () => {
    const call = voxImplant.callConference(
      {
        H264first: true,
        number: params.id as string,
        video: {
          sendVideo: camera,
          receiveVideo: true,
        },
      },
      true,
    );

    setCurrentCall(call);
    console.log('call created');
    setEndpoints(call.getEndpoints().filter(filterSelfEndpoint));
  };

  const onCallDisconnected = async () => {
    if (currentCall) {
      setCurrentCall(null);
    }
  };

  const onCallFailed = () => {
    setCurrentCall(null);
  };

  const onAuthResult = async (event: any) => {
    setStatus('loading');
    if (event.result) {
      createCall();
    } else if (event.code === 302) {
      let response = null;
      try {
        response = await fetch(
          // TODO add to env
          `https://api.dot.dotherapy.ru/api/voximplant_token`,
          {
            method: 'POST',
            body: JSON.stringify({
              login_key: event.key,
              username: user?.userId,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            mode: 'cors',
          },
        );
      } catch (error) {
        setStatus('error');
        // TODO implement notification
        // Notification.error(
        //   'Что-то пошло не так. Невозможно подключиться к сеансу',
        // );
        console.log(error);
      }

      if (response?.ok) {
        response = await response?.json();
      }

      const token = response?.token;

      await voxImplant.loginWithOneTimeKey(
        `${user?.userId}@${env.REACT_APP_VOXIMPLANT_APPLICATION_NAME}.${env.REACT_APP_VOXIMPLANT_ACCOUNT_NAME}.voximplant.com`,
        token,
      );
    }
  };

  const onConnectionClosed = () => {
    setTimeout(() => voxImplant.connect(), 3000);
  };

  const onConnectionEstablished = async () => {
    setStatus('loading');
    await voxImplant.requestOneTimeLoginKey(
      `${user?.userId}@${env.REACT_APP_VOXIMPLANT_APPLICATION_NAME}.${env.REACT_APP_VOXIMPLANT_ACCOUNT_NAME}.voximplant.com`,
    );
    console.log('signed in vox');
  };

  const onConnectionFailed = () => {
    setStatus('disconnected');
    setTimeout(() => voxImplant.connect(), 5000);
  };

  const onSdkReady = async () => {
    await voxImplant.connect();
    console.log('vox connected');
  };

  const onEndpointRemoved = (ev: any) => {
    setParticipantVideo('on');
    setParticipantVoice('off');
    setEndpoints(
      endpoints
        .filter(ep => ep.id !== ev.endpoint.id)
        .filter(filterSelfEndpoint),
    );
    setStatus('connected');
  };

  const onMediaAdded = (ev: any) => {
    const container = document.getElementById('videoContainerCompanion');
    ev.mediaRenderer.render(container);
    setParticipantVideo('on');
  };

  const onMediaRemoved = () => {
    setParticipantVideo('removed');
  };

  const onMediaUpdated = () => {
    setParticipantVideo(remoteVideo === 'on' ? 'removed' : 'on');
  };

  const onVoiceStart = () => {
    setParticipantVoice('on');
  };

  const onVoiceEnd = useCallback(() => {
    if (remoteMicro !== 'removed') setParticipantVoice('off');
  }, [remoteMicro]);

  const onCallUpdated = (ev: any) => {
    setCurrentCall(ev.call);
    setEndpoints(ev.call.getEndpoints().filter(filterSelfEndpoint));
    sendMessage(ConferenceEvents.ASK_STATUS);
  };

  const onCallConnected = (ev: any) => {
    onCallUpdated(ev);
    setStatus('connected');
    sendMessage(ConferenceEvents.CONNECTED);
  };

  const onEndpointAdded = (ev: any) => {
    onCallUpdated(ev);
    if (ev.endpoint.userName !== user?.id) {
      setStatus('not-alone');
    }
  };

  useEffect(() => {
    if (endpoints?.length) {
      endpoints.forEach(endpoint => {
        endpoint.on(EndpointEvents.RemoteMediaAdded, onMediaAdded);
        endpoint.on(EndpointEvents.RemoteMediaRemoved, onMediaRemoved);
        endpoint.on(EndpointEvents.RemoteMediaUpdated, onMediaUpdated);
        endpoint.on(EndpointEvents.InfoUpdated, onMediaUpdated);
        endpoint.on(EndpointEvents.VoiceStart, onVoiceStart);
        endpoint.on(EndpointEvents.VoiceEnd, onVoiceEnd);
      });
    }

    return () => {
      endpoints.forEach(endpoint => {
        endpoint.off(EndpointEvents.RemoteMediaAdded, onMediaAdded);
        endpoint.off(EndpointEvents.RemoteMediaRemoved, onMediaRemoved);
        endpoint.off(EndpointEvents.RemoteMediaUpdated, onMediaUpdated);
        endpoint.off(EndpointEvents.InfoUpdated, onMediaUpdated);
        endpoint.off(EndpointEvents.VoiceStart, onVoiceStart);
        endpoint.off(EndpointEvents.VoiceEnd, onVoiceEnd);
      });
    };
  }, [endpoints]);

  useEffect(() => {
    currentCall?.on(CallEvents.EndpointAdded, onEndpointAdded);
    currentCall?.on(CallEvents.Connected, onCallConnected);
    currentCall?.on(CallEvents.Disconnected, onCallDisconnected);
    currentCall?.on(CallEvents.Failed, onCallFailed);
    currentCall?.on(CallEvents.Updated, onCallUpdated);
    currentCall?.on(CallEvents.EndpointAdded, onCallUpdated);
    currentCall?.on(CallEvents.EndpointRemoved, onEndpointRemoved);

    return () => {
      currentCall?.off(CallEvents.EndpointAdded, onEndpointAdded);
      currentCall?.off(CallEvents.Connected, onCallConnected);
      currentCall?.off(CallEvents.Disconnected, onCallDisconnected);
      currentCall?.off(CallEvents.Failed, onCallFailed);
      currentCall?.off(CallEvents.Updated, onCallUpdated);
      currentCall?.off(CallEvents.EndpointAdded, onCallUpdated);
      currentCall?.off(CallEvents.EndpointRemoved, onEndpointRemoved);
    };
  }, [currentCall]);

  return {
    onAuthResult,
    onConnectionClosed,
    onConnectionEstablished,
    onSdkReady,
    onConnectionFailed,
  };
};
export default useConferenceSubscriptions;
