import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { EPeriod } from '../api/interfaces/EPeriod';
import { IForm } from '../api/interfaces/IForm';
import { ISession } from '../api/interfaces/ISession';
import SessionsHistoryInput from '../api/interfaces/SessionsHistoryInput';
import { getSessionsHistory } from '../api/sessionsHistory.service';

// requestBody: any
const useGetSessionHistory = (): any => {
  const [filters, setFilters] = useState<SessionsHistoryInput>({
    period: EPeriod.Month,
  });
  const [items, setItems] = useState<ISession[]>([]);
  const { data, trigger, isMutating } = useSWRMutation(
    `/ss/v2/sessions/history?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    getSessionsHistory,
  );

  const changeFilters = (data: IForm) => {
    const tempFilters: SessionsHistoryInput = {
      period: data.period,
      dateFrom: data.date?.dateFrom
        ? dayjs(data.date?.dateFrom).format('YYYY-MM-DD')
        : undefined,
      dateTo: data.date?.dateTo
        ? dayjs(data.date?.dateTo).format('YYYY-MM-DD')
        : undefined,
      name: data.name?.trim(),
    };
    setItems([]);
    setFilters(tempFilters);
  };

  const goNext = () => {
    setFilters(prev => ({
      ...prev,
      paging: {
        next: data?.pagingResponse?.next,
        size: 10,
      },
    }));
  };

  const getItem = async () => {
    const res = await trigger(filters || { period: EPeriod.Month });
    setItems(prev => [...prev, ...(res.pagingResponse.content || [])]);
  };

  useEffect(() => {
    getItem();
  }, [filters]);

  return {
    data: items,
    total: data?.totalSessions,
    changeFilters,
    isLoading: isMutating,
    goNext,
  };
};

export default useGetSessionHistory;
