import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { specApiInstance } from '../../../../1_shared/api/apiInstance';
import { ISpecialistData } from '../../../../1_shared/config/interfaces/ISpecialistData';
import { Roles } from '../../../../1_shared/config/interfaces/Roles';
import { IClientPriviewOutput } from '../../../api/interfaces/IClientPriviewOutput';

export const useAuth = (): {
  user: IClientPriviewOutput | ISpecialistData;
  role: Roles;
  logout: () => Promise<void>;
  login: (role: Roles) => void;
  setSpecUser: (spec: ISpecialistData) => void;
  clearUser: () => void;
} => {
  const [roleState, setRoleState] = useState<Roles | null>(null);
  const [user, setUser] = useState<
    IClientPriviewOutput | ISpecialistData | null
  >(null);

  const clearUser = () => {
    setUser(null);
    setRoleState(null);
  };

  const login = async (role: Roles) => {
    setRoleState(role);
    const reqProfile = await specApiInstance.get(
      `${role === Roles.Spec ? 'spec/specialist' : 'aim/client'}/profile`,
    );
    setUser(reqProfile.data);
    delete reqProfile.data.slots;
    localStorage.setItem('user', JSON.stringify(reqProfile.data));
    localStorage.setItem('role', role);
  };

  const logout = async () => {
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    setUser(null);
    setRoleState(null);
    await specApiInstance.post('aim/logout');
  };

  const setSpecUser = (spec: ISpecialistData) => {
    setUser(spec);
  };

  useEffect(() => {
    const getItem =
      !localStorage?.getItem('user') ||
      localStorage?.getItem('user') === 'undefined'
        ? null
        : localStorage?.getItem('user');
    const userLS = !getItem ? null : JSON.parse(`${getItem}`);
    if (userLS) {
      setUser(userLS);
      const role = userLS?.role ?? localStorage.getItem('role') ?? null;
      if (role) {
        setRoleState(role.toUpperCase());
      }
    }
  }, []);

  useEffect(() => {
    if (!Cookies.get('user.data') && !!user) {
      console.log('User not logined');
      logout();
    }
  }, []);

  return {
    user: user as IClientPriviewOutput | ISpecialistData,
    role: roleState as Roles,
    logout,
    login,
    setSpecUser,
    clearUser,
  };
};
