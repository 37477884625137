import initializationUserMedia from './initializationUserMedia';

const getUserMediaDevices = async (type: any, selected_devices: any) => {
  let response;
  let stream: any[] = [];
  switch (type) {
    case 'camera':
      response = await initializationUserMedia(selected_devices[type], false);
      stream = response?.videoStream;

      break;

    case 'microphone':
      response = await initializationUserMedia(false, selected_devices[type]);
      stream = response?.audioStream;

      break;

    case 'speaker':
      response = await initializationUserMedia(false, false);
      stream = response?.speakerStream;

      break;
    default: {
      break;
    }
  }

  let devicesList = [];
  let selectedDevice: any;

  if (stream) {
    devicesList = stream.reduce((items, item) => {
      const value = item.label || item.name;

      items[value] = value;

      if (value === selected_devices[type]) {
        selectedDevice = value;
      } else if (!selectedDevice && stream) {
        selectedDevice = stream[0].name || stream[0].label;
      }

      return items;
    }, {});
  }

  return [devicesList, selectedDevice];
};
export default getUserMediaDevices;
